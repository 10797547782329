<template>
  <div>
    <img class="img__body" :src="imgBody" alt="" />
    <div class="container__logo">
      <img class="logo__clear" :src="imgLogo" alt="" />
    </div>

    <div class="container__flex__test">
      <article class="test">
        <div class="title__test" @click="handleClickTest('ic')">
          <p>Test de instrucciones complejas (IC)</p>
        </div>
        <div class="acceder" @click="accessTo('ic')">
          <p>Acceder</p>
        </div>
        <div class="compartir" @click="shareQuiz('ic')">
          <p>Compartir</p>
        </div>
        <div class="descargar">
          <p>Descargar PDF</p>
        </div>
      </article>

      <article class="test">
        <div class="title__test" @click="handleClickTest('neo')">
          <p>NEO PI R</p>
        </div>
        <div class="acceder" @click="accessTo('neo')">
          <p>Acceder</p>
        </div>
        <div class="compartir" @click="shareQuiz('neo')">
          <p>Compartir</p>
        </div>
        <div class="descargar">
          <p>
            Descargar PDF
          </p>
        </div>
      </article>

      <article class="test">
        <div class="title__test" @click="handleClickTest('disc')">
          <p>
            DISC
          </p>
        </div>
        <div class="acceder" @click="accessTo('disc')">
          <p>Acceder</p>
        </div>
        <div class="compartir" @click="shareQuiz('disc')">
          <p>Compartir</p>
        </div>
        <div class="descargar">
          <p>Descargar PDF</p>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgBody: require("@/assets/img/clear people sin letras.png"),
      imgLogo: require("@/assets/img/clear people naranja (1).png"),
      basePath: "localhost:8081",
      discUrl: "/quiz/resolve/23eb2462-2da6-40be-9ebb-0f6755bdd55d/disc-quiz",
      neoUrl: "/quiz/resolve/23eb2462-2da6-40be-9ebb-0f6755bdd55d/neo-ffi-quiz",
      icUrl: "/quiz/resolve/23eb2462-2da6-40be-9ebb-0f6755bdd55d/ic-quiz",
    };
  },
  methods: {
    shareQuiz(test) {
      if (test === "ic") {
        this.$clipboard(this.basePath + this.icUrl);
      } else if (test === "neo") {
        this.$clipboard(this.basePath + this.neoUrl);
      } else {
        this.$clipboard(this.basePath + this.discUrl);
      }
    },
    accessTo(to) {
      if (to === "ic") {
        window.location.href = this.icUrl;
      } else if (to === "neo") {
        window.location.href = this.neoUrl;
      } else {
        window.location.href = this.discUrl;
      }
    },
    handleClickTest(test) {
      if (test === "ic") {
        this.$router.push({ name: "tests-results", params: { quizId: 1 } });
      } else if (test === "neo") {
        this.$router.push({ name: "tests-results", params: { quizId: 2 } });
      } else if (test === "disc") {
        this.$router.push({ name: "tests-results", params: { quizId: 3 } });
      }
    },
  },
};
</script>
